import { useState } from "react";
import React from "react";
import logo from "./icons/branch.png";


const Homepage = () => {

  return (
    
    //<img className="skeleton" alt="" src={skeleton} />
    <div className="main-container">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Dogwood Lane
        </p>
      </header>
    </div>
  );
};

export default Homepage;