import { useState } from "react";
import React from "react";
import { HalloweenCrawl, DogwoodAddresses } from "../Data/HalloweenData";
import HalloweenContent from "./HalloweenContent";


const Halloween = () => {

  return (
    
    //<img className="skeleton" alt="" src={skeleton} />
    <div className="main-container">
      <div className="halloween-titles">
      <h1 className="title horror-text">Dogwood Halloween House Crawl</h1>
      <h2 className="title2">When: Friday October 28, 2022 6:00PM - 12:00AM
        <br />
        Where: Dogwood Lane (Haunted Hill)
      </h2>
      </div>
      <div className="halloween-content">
        <HalloweenContent stops={HalloweenCrawl}/>
      </div>
    </div>
  );
};

export default Halloween;
