import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { createTheme, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, styled, ThemeProvider, useTheme } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

const drawerWidth = 240;

const menuBar = createTheme({
    palette: {
      primary: {
        main: '#FFFFFF',
      },
    },
  });

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }));

 interface IProps
 {
    openContent: number;
    onSelect: (tabSelect: number) => void;
 };

export default function ButtonAppBar(props: IProps) {

    const theme = useTheme();
    const [open, setOpen] = React.useState(props.openContent > 1 ? true : false);
  
    const handleDrawerClose = () => {
        setOpen(false);
      };
      
    const handleDrawerOpen = () => {
      setOpen(true);
    };

    const handleSelectCotent = (tabSelect: number) => {
      props.onSelect(tabSelect);
      setOpen(false);
    };
  return (
    <Box sx={{ flexGrow: 1 }}>
        <ThemeProvider theme={menuBar}>
      <AppBar color="primary" position="fixed" >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={handleDrawerOpen}

          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      </ThemeProvider>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {['Dogwood Halloween', 'Cookie Exchange'].map((text, index) => (
            <ListItem button component="a" href="/dogwoodhalloween" key={text} disablePadding>
              <ListItemButton>
                <ListItemText onClick={()=>handleSelectCotent(index + 1)}primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
      </Drawer>
    </Box>
  );
}
