import React from 'react';
import logo from './icons/branch.png';
import './App.css';
import MenuBar from './MenuBar';
import { Switch } from '@mui/material';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Halloween from './Halloween/Halloween';
import Homepage from './Homepage';


function App() {
  const [openContent, setOpenContent] = React.useState(0);

  return (
    <BrowserRouter>
    <Routes>
      <Route path = "/" element={<Homepage/>}/>
      <Route path = "/dogwoodhalloween" element = {<Halloween/>}/>
    </Routes>
    <div className="App">
       <MenuBar openContent={openContent} onSelect={setOpenContent}></MenuBar>
    </div>
    </BrowserRouter>

  );
}

export default App;
